import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidePanelService {
    public dataSource = new BehaviorSubject({});
    currentState = this.dataSource.asObservable();
    private modals: any[] = [];

    constructor() {}

    setState(state: string, id: string) {
        const dataModal = {
            state: state,
            id: id
        };
        this.dataSource.next(dataModal);
    }

    add(modal: any) {
        // add modal to array of active modals
        modal.element.hidden = true;
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id;
        const modal: any = this.modals.filter(x => {
            return x.id === id;
        })[0];
        modal.element.hidden = false;
        modal.open();
    }

    close(id: string) {
        // close modal specified by id
        const modal: any = this.modals.filter(x => {
            return x.id === id;
        })[0];
        modal.close();
        setTimeout(() => {
            modal.element.hidden = true;
        }, 500);
    }
}
