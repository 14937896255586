<div class="cursor-pointer" (click)="selectFile();">
    <ngx-file-drop class="w-100" [directory]="false" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="w-100 h-100 d-flex justify-content-center align-items-center text-primary">
                <i class="fa-solid fa-upload mr-1"></i>
                Upload/Drop Image
            </div>
        </ng-template>
    </ngx-file-drop>
    <input type="file" #fileInput style="display: none;" accept="image/*" (change)="selectedFile($event);">
</div>