import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import moment from 'moment';

@Pipe({
	name: 'unixToDateTime'
})
export class UnixToDateTimePipe implements PipeTransform {
	transform(timestamp: number): string {
		return moment.unix(timestamp).utc().format('Do MMM, YYYY | hh:mm A');
		// const date = new Date(timestamp * 1000); // Convert Unix timestamp (seconds) to milliseconds
		// const formattedDate = this.formatDate(date);
		// const formattedTime = this.formatTime(date);
		// return formattedDate + ' | ' + formattedTime;
	}

	private formatDate(date: Date): string {
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'short' });
		const year = date.getFullYear();
		const suffix = this.getOrdinalSuffix(day);
		return `${day}${suffix} ${month}, ${year}`;
	}

	private formatTime(date: Date): string {
		return formatDate(date, 'hh:mm a', 'en-US');
	}

	private getOrdinalSuffix(day: number): string {
		if (day === 1 || day === 21 || day === 31) {
			return 'st';
		} else if (day === 2 || day === 22) {
			return 'nd';
		} else if (day === 3 || day === 23) {
			return 'rd';
		} else {
			return 'th';
		}
	}
}
