import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateObjectToFormate'
})
export class DateObjectToFormatePipe implements PipeTransform {
    transform(value: any, format: string = null): any {
        if (format !== null) {
            return moment(`${value.month}-${value.day}-${value.year}`, 'MM-DD-YYYY').format(format);
        }
        return moment(value).format('MMM Do YY');
    }
}
