import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-search-select-parameters',
    templateUrl: './search-select-parameters.component.html',
    styleUrls: ['./search-select-parameters.component.scss']
})
export class SearchSelectParametersComponent implements AfterViewInit, OnChanges {
    @Input() dropDownList: any;
    @Input() dropDownPlaceholder: any;
    @Input() dropDownListEmptyMessage: any;
    @Input() dropDownListShowLabel: any;
    @ViewChild('searchInput') searchInput: ElementRef;
    @Output() dropDownSearchItems = new EventEmitter<string>();
    @Output() dropDownSelectedItems = new EventEmitter<any>();
    selectedOption: any;

    constructor() { }

    ngAfterViewInit(): void {
        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // get value
            map((event: any) => {
                return event.target.value;
            })
            // if character length greater then 2
            , filter(res => res.length > -1)
            // Time in milliseconds between key events
            , debounceTime(500)
            // If previous query is diffent from current
            , distinctUntilChanged()
            // subscription for response
        ).subscribe((term: string) => {
            // call function from
            this.dropDownSearchItems.emit(term);
        });

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dropDownList && !changes.dropDownList.firstChange) {
            this.dropDownList = changes.dropDownList.currentValue;

        }
    }

    selectItem(items: any) {
        this.dropDownSelectedItems.emit(items);
    }

}
