import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateI18nTitlecase',
    pure: false
})
export class TranslateI18nTitlecasePipe implements PipeTransform {
    constructor(private translateService: TranslateService, private titleCasePipe: TitleCasePipe) {

    }
    transform(value: any, args?: any): any {
        return this.titleCasePipe.transform(this.translateService.instant(value));
    }
}
