import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: any, args: any): any {
        let truncateString = value;
        if (value.length > args) {
            truncateString = value.substring(0, args) + '...';
        }
        return truncateString;
    }
}
