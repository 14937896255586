import { Pipe, PipeTransform } from '@angular/core';
import { lowerCase } from 'lodash';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    /**
     * Returns the searched data
     * @param data
     * @param searchParam
     * @param searchType
     */
    transform(
        data: any,
        searchParam?: string,
        searchType?: Array<string>
    ): any {
        if (!data) {
            return null;
        }
        if (searchParam === '' || searchParam === undefined) {
            return data;
        }

        if (searchType.includes('invoiceType')) {
            return data.filter((response: any) => {
                if (response && response.publicData) {
                    let type = response.publicData.type;
                    if (type === 'single') {
                        type = 'onetime';
                    }
                    return (
                        lowerCase(response.publicData.title).search(
                            lowerCase(searchParam)
                        ) !== -1 ||
                        lowerCase(response.publicData.type && type).search(
                            lowerCase(searchParam)
                        ) !== -1 ||
                        lowerCase(response.publicData.price).search(
                            lowerCase(searchParam)
                        ) !== -1
                    );
                }
            });
        } else if (searchType.includes('amount')) {
            return data.filter((response: any) => {
                return (
                    lowerCase(
                        response &&
                        response.customer &&
                        response.customer.metadata &&
                        response.customer.metadata.Name
                    ).search(lowerCase(searchParam)) !== -1 ||
                    lowerCase(
                        response && response.customer && response.customer.email
                    ).search(lowerCase(searchParam)) !== -1 ||
                    lowerCase(response && response.amount).search(
                        lowerCase(searchParam)
                    ) !== -1 ||
                    lowerCase(
                        response && response.plan && response.plan.amount
                    ).search(lowerCase(searchParam)) !== -1
                );
            });
        }
        return data.filter((response: any) => {
            return (
                lowerCase(
                    response && response.metadata && response.metadata.Name
                ).search(lowerCase(searchParam)) !== -1 ||
                lowerCase(response && response.email).search(
                    lowerCase(searchParam)
                ) !== -1
            );
        });
    }
}
