import { Component } from '@angular/core';

@Component({
  selector: 'app-remove-icon',
  templateUrl: './remove-icon.component.html',
  styleUrl: './remove-icon.component.scss'
})
export class RemoveIconComponent {

}
