import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, UrlTree } from '@angular/router';
import { MessageService } from '../../services/data-sharing.service';
import { EventService } from '../../event/event.service';

@Component({
	selector: 'app-custom-button-group',
	templateUrl: './custom-button-group.component.html',
	styleUrl: './custom-button-group.component.scss'
})
export class CustomButtonGroupComponent implements OnInit {
	path: string;
	showEarlyBirdPrice: boolean = false;
	eventId;
	eventData
	isEventPaid: boolean = true;
	isEventEditable: boolean = false;
	hasEarlyBirdPrices: boolean = false;
	constructor(
		private router: Router,
		private messageService: MessageService,
		private route: ActivatedRoute,
		private eventService: EventService,
	) {
		this.showEarlyBirdPrice = localStorage.getItem('showEarlyBirdPrice') === 'true' ? true : false;
		this.messageService.currentMessage.subscribe((data) => {
			if (data['showEarlyBirdPrice']) {
				this.showEarlyBirdPrice = true;
			}
		})
		this.path = this.extractPathFromUrl(this.router.url);
	}

	extractPathFromUrl(url: string): string {
		const urlTree: UrlTree = this.router.parseUrl(url);
		return urlTree.root.children['primary'].segments.map(segment => segment.path).join('/');
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			this.eventId = params['id'];
			this.isEventEditable = params['isEdit'] ? true : false;

			if (this.eventId) {

				this.eventService.getPublicEventById(this.eventId).subscribe({
					next: (res) => {
						res.isPaid ? this.isEventPaid = true : this.isEventPaid = false;
						const tickets = res.tickets;
						if (tickets && tickets.length > 0) {
							this.hasEarlyBirdPrices = tickets.some(ticket => ticket.earlyBirdPrices && ticket.earlyBirdPrices.length > 0);
						} else {
							this.hasEarlyBirdPrices = false;
						}
					}, error: (err) => {
						console.log('error while getting event details')
					},
				});
			}
		});
	}


	navigateTo(path: string) {
		if (this.isEventEditable) {
			const queryParams: NavigationExtras = {
				queryParams: { id: this.eventId, isEdit: true }
			};
			this.router.navigate([path], queryParams);
		}
	}

}
