import {
    AfterViewInit,
    ChangeDetectorRef, Component, ElementRef, Input,
    OnInit, ViewChild
} from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, AfterViewInit {
    @ViewChild('tooltipButton') tooltipButton: ElementRef;
    @Input()
    tooltipTitle: any;
    @Input()
    timestamp: any;
    @Input()
    description: any;
    isShowTooltip = false;
    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (this.timestamp) {
            const start = moment.unix(this.timestamp);
            const today = moment(moment(Date.now()).format('YYYY-MM-DD')).unix();
            const end = moment.unix(today);
            const days = Math.ceil(moment.duration(start.diff(end)).asDays());
            if (Math.abs(days) < 30) {
                this.isShowTooltip = true;
            }
        }

    }

    ngAfterViewInit() {
        if (this.isShowTooltip) {
            this.tooltipButton.nativeElement.click();
        }
        this.cdr.detectChanges();
    }

    close() {
        if (this.isShowTooltip) {
            this.tooltipButton.nativeElement.click();
        }
    }

}
