<!-- <ng-template #tipContent>
  <div (click)="close()" class="close-button tooltip-close-button">
    <i class="fa fa-times" aria-hidden="true"></i>
  </div>
  <span>New</span>
</ng-template> -->
<ng-template #tipContent>
    <div style="width: 18rem">
        <div class="card card-border">
            <div class="card-body">
                <h4 class="card-title" *ngIf="isShowTooltip">
                    {{ tooltipTitle | translate }}
                </h4>
                <p class="card-text">
                    {{ description }}
                </p>
                <a href="javascript:void(0)" (click)="close()" class="btn btn-primary" translate>global.label.cancel</a>
            </div>
        </div>
    </div>
</ng-template>
<i
    *ngIf="isShowTooltip"
    #tooltipButton
    type="button"
    [autoClose]="false"
    [ngbTooltip]="tipContent"
    triggers="click"
    class="far fa-question-circle small p-1"
    tooltipClass="custom-tooltip"
></i>
