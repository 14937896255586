<div class="btn-group py-4 w-100" role="group" aria-label="Basic radio toggle button group">
	<input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
		[checked]="path === 'event/create'" [disabled]="!isEventEditable" (click)="navigateTo('event/create')">
	<label class="btn btn-sm btn-outline-primary left-tab" for="btnradio1">Event details</label>

	<input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
		[checked]="path === 'event/create/tickets'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/tickets')">
	<label class="btn btn-sm btn-outline-primary common-tab" for="btnradio2">Ticket
		Type</label>

	<input *ngIf="( hasEarlyBirdPrices)" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"
		[checked]="path === 'event/create/early-bird-price'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/early-bird-price')">
	<label *ngIf="( hasEarlyBirdPrices)" class="btn btn-sm btn-outline-primary common-tab" for="btnradio3">Early Bird
		Price</label>

	<input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off"
		[checked]="path === 'event/create/session'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/session')">
	<label class="btn btn-sm btn-outline-primary common-tab" for="btnradio4">Session</label>

	<input type="radio" class="btn-check" name="btnradio" id="btnradio5" autocomplete="off"
		[checked]="path === 'event/create/speaker'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/speaker')">
	<label class="btn btn-sm btn-outline-primary common-tab" for="btnradio5">Speaker</label>

	<input *ngIf="isEventPaid" type="radio" class="btn-check" name="btnradio" id="btnradio6" autocomplete="off"
		[checked]="path === 'event/create/discount'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/discount')">
	<label *ngIf=" isEventPaid" class="btn btn-sm btn-outline-primary common-tab" for="btnradio6">Discounts</label>

	<input type="radio" class="btn-check" name="btnradio" id="btnradio7" autocomplete="off"
		[checked]="path === 'event/create/reg-form'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/reg-form')">
	<label class="btn btn-sm btn-outline-primary common-tab" for="btnradio7">Reg Form</label>

	<input type="radio" class="btn-check" name="btnradio" id="btnradio8" autocomplete="off"
		[checked]="path === 'event/create/sponsors'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/sponsors')">
	<label class="btn btn-sm btn-outline-primary common-tab" for="btnradio8">Sponsors</label>

	<input type="radio" class="btn-check" name="btnradio" id="btnradio9" autocomplete="off"
		[checked]="path === 'event/create/add-engagement'" [disabled]="!isEventEditable"
		(click)="navigateTo('event/create/add-engagement')">
	<label class="btn btn-sm btn-outline-primary right-tab" for="btnradio9">Engagement</label>
</div>