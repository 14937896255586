import * as i0 from '@angular/core';
import { Pipe, Inject, NgModule, Injectable } from '@angular/core';
import { PureAbility } from '@casl/ability';
import { Observable } from 'rxjs';

class AblePipe {
    constructor(ability) {
        this._ability = ability;
    }
    transform(...args) {
        return this._ability.can(...args);
    }
}
AblePipe.ɵfac = function AblePipe_Factory(t) { return new (t || AblePipe)(i0.ɵɵdirectiveInject(PureAbility, 16)); };
AblePipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "able", type: AblePipe, pure: false });
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AblePipe, [{
        type: Pipe,
        args: [{ name: 'able', pure: false }]
    }], () => [{ type: undefined, decorators: [{
                type: Inject,
                args: [PureAbility]
            }] }], null); })();
class AblePurePipe {
    constructor(ability) {
        this._ability = ability;
    }
    // TODO: use computed signals https://github.com/angular/angular/issues/47553
    transform(...args) {
        return new Observable((s) => {
            const emit = () => s.next(this._ability.can(...args));
            emit();
            return this._ability.on('updated', emit);
        });
    }
}
AblePurePipe.ɵfac = function AblePurePipe_Factory(t) { return new (t || AblePurePipe)(i0.ɵɵdirectiveInject(PureAbility, 16)); };
AblePurePipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "ablePure", type: AblePurePipe, pure: true });
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AblePurePipe, [{
        type: Pipe,
        args: [{ name: 'ablePure' }]
    }], () => [{ type: undefined, decorators: [{
                type: Inject,
                args: [PureAbility]
            }] }], null); })();

class AbilityModule {
}
AbilityModule.ɵfac = function AbilityModule_Factory(t) { return new (t || AbilityModule)(); };
AbilityModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AbilityModule });
AbilityModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({});
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbilityModule, [{
        type: NgModule,
        args: [{
                declarations: [
                    AblePipe,
                    AblePurePipe,
                ],
                exports: [
                    AblePipe,
                    AblePurePipe,
                ],
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AbilityModule, { declarations: [AblePipe,
        AblePurePipe], exports: [AblePipe,
        AblePurePipe] }); })();

class AbilityService {
    constructor(ability) {
        this.ability$ = new Observable((observer) => {
            observer.next(ability);
            return ability.on('updated', () => observer.next(ability));
        });
    }
}
AbilityService.ɵfac = function AbilityService_Factory(t) { return new (t || AbilityService)(i0.ɵɵinject(PureAbility)); };
AbilityService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AbilityService, factory: AbilityService.ɵfac });
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbilityService, [{
        type: Injectable
    }], () => [{ type: undefined, decorators: [{
                type: Inject,
                args: [PureAbility]
            }] }], null); })();

export { AbilityModule, AbilityService, AblePipe, AblePurePipe };

