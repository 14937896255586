import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform {
    transform(value: any, args?: any, format: string = null): any {
        if (format !== null) {
            return moment(value).format(format);
        }
        return moment(value).format('MMM Do YY');
    }
}
