import * as i0 from '@angular/core';
import { Pipe, EventEmitter, InjectionToken, Optional, Inject, NgModule } from '@angular/core';
import moment from 'moment';

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class AddPipe {
  transform(value, amount, unit) {
    if (typeof amount === 'undefined' || typeof amount === 'number' && typeof unit === 'undefined') {
      throw new Error('AddPipe: missing required arguments');
    }
    return moment(value).add(amount, unit);
  }
}
AddPipe.ɵfac = function AddPipe_Factory(t) {
  return new (t || AddPipe)();
};
AddPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amAdd",
  type: AddPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AddPipe, [{
    type: Pipe,
    args: [{
      name: 'amAdd'
    }]
  }], null, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class CalendarPipe {
  constructor(cdRef, ngZone) {
    this.cdRef = cdRef;
    this.ngZone = ngZone;
    // using a single static timer for all instances of this pipe for performance reasons
    CalendarPipe.initTimer(ngZone);
    CalendarPipe.refs++;
    // values such as Today will need to be replaced with Yesterday after midnight,
    // so make sure we subscribe to an EventEmitter that we set up to emit at midnight
    this.midnightSub = CalendarPipe.midnight.subscribe(() => {
      this.ngZone.run(() => this.cdRef.markForCheck());
    });
  }
  transform(value, ...args) {
    let formats = null;
    let referenceTime = null;
    for (let i = 0, len = args.length; i < len; i++) {
      if (args[i] !== null) {
        if (typeof args[i] === 'object' && !moment.isMoment(args[i])) {
          formats = args[i];
        } else {
          referenceTime = moment(args[i]);
        }
      }
    }
    return moment(value).calendar(referenceTime, formats);
  }
  ngOnDestroy() {
    if (CalendarPipe.refs > 0) {
      CalendarPipe.refs--;
    }
    if (CalendarPipe.refs === 0) {
      CalendarPipe.removeTimer();
    }
    this.midnightSub.unsubscribe();
  }
  static initTimer(ngZone) {
    // initialize the timer
    if (!CalendarPipe.midnight) {
      CalendarPipe.midnight = new EventEmitter();
      if (typeof window !== 'undefined') {
        const timeToUpdate = CalendarPipe._getMillisecondsUntilUpdate();
        CalendarPipe.timer = ngZone.runOutsideAngular(() => {
          return window.setTimeout(() => {
            // emit the current date
            CalendarPipe.midnight.emit(new Date());
            // refresh the timer
            CalendarPipe.removeTimer();
            CalendarPipe.initTimer(ngZone);
          }, timeToUpdate);
        });
      }
    }
  }
  static removeTimer() {
    if (CalendarPipe.timer) {
      window.clearTimeout(CalendarPipe.timer);
      CalendarPipe.timer = null;
      CalendarPipe.midnight = null;
    }
  }
  static _getMillisecondsUntilUpdate() {
    const now = moment();
    const tomorrow = moment().startOf('day').add(1, 'days');
    const timeToMidnight = tomorrow.valueOf() - now.valueOf();
    return timeToMidnight + 1000; // 1 second after midnight
  }
}
/**
 * Internal reference counter, so we can clean up when no instances are in use
 */
CalendarPipe.refs = 0;
CalendarPipe.timer = null;
CalendarPipe.midnight = null;
CalendarPipe.ɵfac = function CalendarPipe_Factory(t) {
  return new (t || CalendarPipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16), i0.ɵɵdirectiveInject(i0.NgZone, 16));
};
CalendarPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amCalendar",
  type: CalendarPipe,
  pure: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalendarPipe, [{
    type: Pipe,
    args: [{
      name: 'amCalendar',
      pure: false
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class DateFormatPipe {
  transform(value, ...args) {
    if (!value) {
      return '';
    }
    return moment(value).format(args[0]);
  }
}
DateFormatPipe.ɵfac = function DateFormatPipe_Factory(t) {
  return new (t || DateFormatPipe)();
};
DateFormatPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amDateFormat",
  type: DateFormatPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'amDateFormat'
    }]
  }], null, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class DifferencePipe {
  transform(value, otherValue, unit, precision) {
    const date = moment(value);
    const date2 = otherValue !== null ? moment(otherValue) : moment();
    return date.diff(date2, unit, precision);
  }
}
DifferencePipe.ɵfac = function DifferencePipe_Factory(t) {
  return new (t || DifferencePipe)();
};
DifferencePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amDifference",
  type: DifferencePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DifferencePipe, [{
    type: Pipe,
    args: [{
      name: 'amDifference'
    }]
  }], null, null);
})();
const NGX_MOMENT_OPTIONS = new InjectionToken('NGX_MOMENT_OPTIONS');
class DurationPipe {
  constructor(momentOptions) {
    this.allowedUnits = ['ss', 's', 'm', 'h', 'd', 'M'];
    this._applyOptions(momentOptions);
  }
  transform(value, ...args) {
    if (typeof args === 'undefined' || args.length !== 1) {
      throw new Error('DurationPipe: missing required time unit argument');
    }
    return moment.duration(value, args[0]).humanize();
  }
  _applyOptions(momentOptions) {
    if (!momentOptions) {
      return;
    }
    if (!!momentOptions.relativeTimeThresholdOptions) {
      const units = Object.keys(momentOptions.relativeTimeThresholdOptions);
      const filteredUnits = units.filter(unit => this.allowedUnits.indexOf(unit) !== -1);
      filteredUnits.forEach(unit => {
        moment.relativeTimeThreshold(unit, momentOptions.relativeTimeThresholdOptions[unit]);
      });
    }
  }
}
DurationPipe.ɵfac = function DurationPipe_Factory(t) {
  return new (t || DurationPipe)(i0.ɵɵdirectiveInject(NGX_MOMENT_OPTIONS, 24));
};
DurationPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amDuration",
  type: DurationPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DurationPipe, [{
    type: Pipe,
    args: [{
      name: 'amDuration'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [NGX_MOMENT_OPTIONS]
      }]
    }];
  }, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class FromUnixPipe {
  transform(value, ...args) {
    return typeof value === 'string' ? moment.unix(parseInt(value, 10)) : moment.unix(value);
  }
}
FromUnixPipe.ɵfac = function FromUnixPipe_Factory(t) {
  return new (t || FromUnixPipe)();
};
FromUnixPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amFromUnix",
  type: FromUnixPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FromUnixPipe, [{
    type: Pipe,
    args: [{
      name: 'amFromUnix'
    }]
  }], null, null);
})();
class ParsePipe {
  transform(value, formats) {
    return moment(value, formats);
  }
}
ParsePipe.ɵfac = function ParsePipe_Factory(t) {
  return new (t || ParsePipe)();
};
ParsePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amParse",
  type: ParsePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ParsePipe, [{
    type: Pipe,
    args: [{
      name: 'amParse'
    }]
  }], null, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class FromUtcPipe {
  transform(value, formats, ...args) {
    return formats ? moment.utc(value, formats) : moment.utc(value);
  }
}
FromUtcPipe.ɵfac = function FromUtcPipe_Factory(t) {
  return new (t || FromUtcPipe)();
};
FromUtcPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amFromUtc",
  type: FromUtcPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FromUtcPipe, [{
    type: Pipe,
    args: [{
      name: 'amFromUtc'
    }]
  }], null, null);
})();
class IsAfterPipe {
  transform(value, otherValue, unit) {
    return moment(value).isAfter(moment(otherValue), unit);
  }
}
IsAfterPipe.ɵfac = function IsAfterPipe_Factory(t) {
  return new (t || IsAfterPipe)();
};
IsAfterPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amIsAfter",
  type: IsAfterPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsAfterPipe, [{
    type: Pipe,
    args: [{
      name: 'amIsAfter'
    }]
  }], null, null);
})();
class IsBeforePipe {
  transform(value, otherValue, unit) {
    return moment(value).isBefore(moment(otherValue), unit);
  }
}
IsBeforePipe.ɵfac = function IsBeforePipe_Factory(t) {
  return new (t || IsBeforePipe)();
};
IsBeforePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amIsBefore",
  type: IsBeforePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsBeforePipe, [{
    type: Pipe,
    args: [{
      name: 'amIsBefore'
    }]
  }], null, null);
})();
class LocalTimePipe {
  transform(value) {
    return moment(value).local();
  }
}
LocalTimePipe.ɵfac = function LocalTimePipe_Factory(t) {
  return new (t || LocalTimePipe)();
};
LocalTimePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amLocal",
  type: LocalTimePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalTimePipe, [{
    type: Pipe,
    args: [{
      name: 'amLocal'
    }]
  }], null, null);
})();
class LocalePipe {
  transform(value, locale) {
    return moment(value).locale(locale);
  }
}
LocalePipe.ɵfac = function LocalePipe_Factory(t) {
  return new (t || LocalePipe)();
};
LocalePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amLocale",
  type: LocalePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalePipe, [{
    type: Pipe,
    args: [{
      name: 'amLocale'
    }]
  }], null, null);
})();
class ParseZonePipe {
  transform(value) {
    return moment.parseZone(value);
  }
}
ParseZonePipe.ɵfac = function ParseZonePipe_Factory(t) {
  return new (t || ParseZonePipe)();
};
ParseZonePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amParseZone",
  type: ParseZonePipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ParseZonePipe, [{
    type: Pipe,
    args: [{
      name: 'amParseZone'
    }]
  }], null, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class SubtractPipe {
  transform(value, amount, unit) {
    if (typeof amount === 'undefined' || typeof amount === 'number' && typeof unit === 'undefined') {
      throw new Error('SubtractPipe: missing required arguments');
    }
    return moment(value).subtract(amount, unit);
  }
}
SubtractPipe.ɵfac = function SubtractPipe_Factory(t) {
  return new (t || SubtractPipe)();
};
SubtractPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amSubtract",
  type: SubtractPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubtractPipe, [{
    type: Pipe,
    args: [{
      name: 'amSubtract'
    }]
  }], null, null);
})();

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
class TimeAgoPipe {
  constructor(cdRef, ngZone) {
    this.cdRef = cdRef;
    this.ngZone = ngZone;
  }
  format(m) {
    return m.from(moment(), this.lastOmitSuffix);
  }
  transform(value, omitSuffix, formatFn) {
    if (this.hasChanged(value, omitSuffix)) {
      this.lastTime = this.getTime(value);
      this.lastValue = value;
      this.lastOmitSuffix = omitSuffix;
      this.lastLocale = this.getLocale(value);
      this.formatFn = formatFn || this.format.bind(this);
      this.removeTimer();
      this.createTimer();
      this.lastText = this.formatFn(moment(value));
    } else {
      this.createTimer();
    }
    return this.lastText;
  }
  ngOnDestroy() {
    this.removeTimer();
  }
  createTimer() {
    if (this.currentTimer) {
      return;
    }
    const momentInstance = moment(this.lastValue);
    const timeToUpdate = this.getSecondsUntilUpdate(momentInstance) * 1000;
    this.currentTimer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.lastText = this.formatFn(moment(this.lastValue));
          this.currentTimer = null;
          this.ngZone.run(() => this.cdRef.markForCheck());
        }, timeToUpdate);
      } else {
        return null;
      }
    });
  }
  removeTimer() {
    if (this.currentTimer) {
      window.clearTimeout(this.currentTimer);
      this.currentTimer = null;
    }
  }
  getSecondsUntilUpdate(momentInstance) {
    const howOld = Math.abs(moment().diff(momentInstance, 'minute'));
    if (howOld < 1) {
      return 1;
    } else if (howOld < 60) {
      return 30;
    } else if (howOld < 180) {
      return 300;
    } else {
      return 3600;
    }
  }
  hasChanged(value, omitSuffix) {
    return this.getTime(value) !== this.lastTime || this.getLocale(value) !== this.lastLocale || omitSuffix !== this.lastOmitSuffix;
  }
  getTime(value) {
    if (moment.isDate(value)) {
      return value.getTime();
    } else if (moment.isMoment(value)) {
      return value.valueOf();
    } else {
      return moment(value).valueOf();
    }
  }
  getLocale(value) {
    return moment.isMoment(value) ? value.locale() : moment.locale();
  }
}
TimeAgoPipe.ɵfac = function TimeAgoPipe_Factory(t) {
  return new (t || TimeAgoPipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16), i0.ɵɵdirectiveInject(i0.NgZone, 16));
};
TimeAgoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amTimeAgo",
  type: TimeAgoPipe,
  pure: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimeAgoPipe, [{
    type: Pipe,
    args: [{
      name: 'amTimeAgo',
      pure: false
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class UtcPipe {
  transform(value) {
    return moment(value).utc();
  }
}
UtcPipe.ɵfac = function UtcPipe_Factory(t) {
  return new (t || UtcPipe)();
};
UtcPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "amUtc",
  type: UtcPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UtcPipe, [{
    type: Pipe,
    args: [{
      name: 'amUtc'
    }]
  }], null, null);
})();
const ANGULAR_MOMENT_PIPES = [AddPipe, CalendarPipe, DateFormatPipe, DifferencePipe, DurationPipe, FromUnixPipe, ParsePipe, SubtractPipe, TimeAgoPipe, UtcPipe, FromUtcPipe, LocalTimePipe, LocalePipe, ParseZonePipe, IsBeforePipe, IsAfterPipe];
class MomentModule {
  static forRoot(options) {
    return {
      ngModule: MomentModule,
      providers: [{
        provide: NGX_MOMENT_OPTIONS,
        useValue: {
          ...options
        }
      }]
    };
  }
}
MomentModule.ɵfac = function MomentModule_Factory(t) {
  return new (t || MomentModule)();
};
MomentModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MomentModule
});
MomentModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MomentModule, [{
    type: NgModule,
    args: [{
      declarations: ANGULAR_MOMENT_PIPES,
      exports: ANGULAR_MOMENT_PIPES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AddPipe, CalendarPipe, DateFormatPipe, DifferencePipe, DurationPipe, FromUnixPipe, FromUtcPipe, IsAfterPipe, IsBeforePipe, LocalTimePipe, LocalePipe, MomentModule, NGX_MOMENT_OPTIONS, ParsePipe, ParseZonePipe, SubtractPipe, TimeAgoPipe, UtcPipe };
