<div class="alert-bt-l">
    <div *ngFor="let messages of allMessages">
        <div
            #toast
            *ngIf="messages.hasOwnProperty('type')"
            class="alert alert-{{ messages.type }} alert-box alert-dismissible fade show"
            role="alert"
        >
            <span class="alert-inner--icon"><i class="ni"></i></span>
            <span class="alert-inner--text">
                <span id="{{ messages.message }}" translate>{{ messages.message }}</span>
            </span>
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                id="{{ messages.message }}_close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
