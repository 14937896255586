import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent implements OnInit {
	@Input() droppedFn: (event: any) => void;
	@Input() selectedFileFn: (event: any) => void;
	@ViewChild('fileInput') fileInput: ElementRef;

	constructor() { }

	ngOnInit(): void {
		console.log('File Upload Component')
	}

	selectFile() {
		this.fileInput.nativeElement.click();
	}

	dropped(event: any) {
		if (this.droppedFn) {
			this.droppedFn(event);
		}
	}

	fileOver(event: any) { }

	fileLeave(event: any) { }

	selectedFile(event: any) {
		console.log(event);
		console.log(this.selectedFileFn);
		if (this.selectedFileFn) {
			this.selectedFileFn(event);
		}
	}
}
