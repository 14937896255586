import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unixToTime'
})
export class UnixToTimePipe implements PipeTransform {

    transform(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        const hours = date.getHours();
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const period = hours >= 12 ? 'PM' : 'AM';
        let formattedHours = hours % 12;
        formattedHours = formattedHours ? formattedHours : 12;
        return `${formattedHours}:${minutes} ${period}`;
    }

}
