import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { SidePanelService } from './sidepanel.service';

@Component({
    selector: 'app-right-sidepanel',
    templateUrl: './sidepanel.component.html',
    styleUrls: ['sidepanel.component.scss']
})
export class SidepanelComponent implements OnInit, OnDestroy {
    @Input()
    id: string;
    private element: any;

    constructor(
        private modalService: SidePanelService,
        private el: ElementRef
    ) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);
        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        document.body.classList.add('modal-open');
        document.body.classList.remove('modal-close');
    }

    // close modal
    close(): void {
        document.body.classList.add('modal-close');
        document.body.classList.remove('modal-open');
    }
}
