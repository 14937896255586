import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
	selector: 'app-country-dropdown',
	templateUrl: './country-dropdown.component.html',
	styleUrls: ['./country-dropdown.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CountryDropdownComponent {

	@Input() dropDownControlCountryList: any;
	@Input() dropDownControlPlaceholder: any;
	@Input() dropDownControlName: string | undefined;
	@Output() dropDownControlFunction: EventEmitter<any> = new EventEmitter();

	constructor() { }

}
