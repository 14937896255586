import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-search-parameters',
    templateUrl: './search-parameters.component.html',
    styleUrls: ['./search-parameters.component.scss']
})
export class SearchParametersComponent implements AfterViewInit {
    @ViewChild('searchInput') searchInput: ElementRef;
    @Output() callFunction = new EventEmitter<string>();
    constructor() { }

    ngAfterViewInit(): void {
        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // get value
            map((event: any) => {
                return event.target.value;
            })
            // if character length greater then 2
            , filter(res => res.length > -1)
            // Time in milliseconds between key events
            , debounceTime(1000)
            // If previous query is diffent from current
            , distinctUntilChanged()
            // subscription for response
        ).subscribe((term: string) => {
            // call function from
            this.callFunction.emit(term);
        });
    }

}
