import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EventService {

	constructor(
		private http: HttpClient,
	) { }

	/***
		* Used for registration.
		*/
	public getEventList(data): Observable<any> {
		return this.http.post<any>(environment.REST_URL + '/events/find', data);
	}

	public getEventTypes(): Observable<any> {
		return this.http.get<any>(environment.REST_URL + '/admin/event-types');
	}

	public getEventCategories(data): Observable<any> {
		return this.http.post<any>(environment.REST_URL + '/event-categories/find', data);
	}

	public getEventOrganizer(): Observable<any> {
		// change URL
		return this.http.get<any>(environment.REST_URL + '/users/list');
	}

	public getInventories(data): Observable<any> {
		return this.http.post<any>(environment.REST_URL + '/inventory/find', data);
	}

	public createEvent(data): Observable<any> {
		return this.http.post<any>(environment.REST_URL + '/events', data);
	}

	public updateEvent(data, id): Observable<any> {
		return this.http.put<any>(environment.REST_URL + '/events/' + id, data);
	}

	public getEventById(id: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/events/${id}`);
	}

	public getPublicEventById(id: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration/event/${id}`);
	}

	public deleteEventById(id: string): Observable<any> {
		return this.http.delete<any>(environment.REST_URL + `/events/${id}`);
	}


	public getRegistrationDetailsId(id: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration/getRegistrationBy/${id}`);
	}

	public getRegistrationReportDetailsId(eventId: string, orderNumber: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration/getRegistrationDetailBy/${eventId}/${orderNumber}`);
	}

	public updateRegistrationStatus(payload): Observable<any> {
		return this.http.post<any>(environment.REST_URL + `/registration/event/action`, payload);
	}

	public deleteRegistrationReportByEventIdAndRegistrationOrderId(eventId: string, registrationsOrderId: string): Observable<any> {
		return this.http.delete<any>(environment.REST_URL + `/registration/${eventId}/${registrationsOrderId}`);
	}

	public sendConfirmedEmailToAllAttendee(eventId: string): Observable<any> {
		return this.http.post<any>(environment.REST_URL + `/registration/${eventId}/confirmed-all-attendee`, null);
	}

	public getRegistrationEventDetailsById(id: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration-events/${id}`);
	}

	public createRegistration(data: any): Observable<any> {
		return this.http.post<any>(environment.REST_URL + `/registration`, data);
	}

	public updateRegistration(id, data: any): Observable<any> {
		return this.http.put<any>(environment.REST_URL + `/registration/${id}`, data);
	}

	public getRegistration(id): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration/${id}`);
	}

	public getTicketsByOrderId(data: { orderNumber: number }): Observable<any> {
		return this.http.post<any>(environment.REST_URL + `/registration/get-ticket`, data);
	}

	public getFormQuestionByEventId(id: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration-events/form/question/${id}`);
	}

	public getFormQuestionResponseByRegistrationId(registrationId: string): Observable<any> {
		return this.http.get<any>(environment.REST_URL + `/registration-query/questions/${registrationId}`);
	}

	public updateRegistrationPersonalDetailsAndQuestions(id, data: any): Observable<any> {
		return this.http.put<any>(environment.REST_URL + `/registration/${id}/update`, data);
	}

	public getEmailHistory(data: any): Observable<any> {
		return this.http.post<any>(environment.REST_URL + `/attendee-emails/find`, data);
	}

	public getPaymentHistory(data: any): Observable<any> {
		return this.http.post<any>(environment.REST_URL + `/payments/find`, data);
	}

	public exportExcel(eventId: string): Observable<any> {
		return this.http.get<Blob>(environment.REST_URL + `/registration/${eventId}/export`, {
			responseType: 'blob' as 'json'
		});
	}
}
