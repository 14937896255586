import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timestampIsAfter'
})
export class TimestampIsAfterPipe implements PipeTransform {
    transform(value: any, args?: any): boolean {
        if (value) {
            const start = moment.unix(value);
            const today = moment(moment(Date.now()).format('YYYY-MM-DD')).unix();
            const end = moment.unix(today);
            return moment(start).isAfter(end);
        }
        return true;
    }
}
