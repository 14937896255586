<div class="form-group">
    <input
        class="form-control form-control-alternative dropdown-toggle w-100"
        data-toggle="dropdown"
        #searchInput
        [placeholder]="dropDownPlaceholder"
    />
    <div class="dropdown-menu dropdown-menu-wide dropdown-menu-right">
        <span class="dropdown-item" *ngIf="!dropDownList?.length">{{ dropDownListEmptyMessage }}</span>

        <ng-template ngIf="dropDownList?.length">
            <a *ngFor="let item of dropDownList" class="dropdown-item" (click)="selectItem(item)">
                {{ item.showLabel }}
            </a>
        </ng-template>
    </div>
</div>
