import { NgClass } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { purgeQueryCache } from '../../constant';
import { I18nService } from '../../core';

@Component({
	selector: 'app-language-dropdown',
	standalone: true,
	imports: [
		NgClass,
		TranslateModule
	],
	template: `
    <li class="nav-item dropdown px-2 d-flex align-items-center">
        <a
            href="javascript:;"
            class=" badge bg-light text-dark"
            id="language-dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            <span class="text-dark" translate="translation.label.{{ currentLanguage }}"></span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end me-sm-n4" aria-labelledby="language-dropdown">
            @for (lang of languages; track lang) {
            <li class="px-2 py-1">
                <a
                    class="dropdown-item border-radius-md"
                    href="javascript:;"
                    (click)="changeLanguage(lang)"
                    [ngClass]="lang === currentLanguage ? 'bg-primary text-white' : ''"
                >
                    <span translate="translation.label.{{ lang }}"></span>
                </a>
            </li>
            }
        </ul>
    </li>
  `,
	styleUrl: './language-dropdown.component.scss'
})
export class LanguageDropdownComponent implements OnInit {
	private readonly i18nService = inject(I18nService);

	@Input() isUpdateUserLang = true;

	async ngOnInit() {
		console.log('ngOnInit')
	}

	async changeLanguage(language: string) {
		this.setLanguage(language);
		try {
			if (this.isUpdateUserLang) {
				purgeQueryCache(['me']);
			}
		} catch (error) {
			console.error('', error);
		}
	}

	setLanguage(language: string) {
		this.i18nService.language = language;
		this.i18nService.selectedLanguage.emit(language);
	}

	get currentLanguage(): string {
		return this.i18nService.language;
	}

	get languages(): string[] {
		return this.i18nService.supportedLanguages;
	}

}
