import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'unixToDate'
})
export class UnixToDatePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';

        const milliseconds = parseInt(value) * 1000;
        const date = new Date(milliseconds);
        const formattedDate = new DatePipe('en-US').transform(date, 'dd/MM/yyyy');

        return formattedDate;
    }
}
