<ng-select
    [items]="dropDownControlCountryList"
    [editableSearchTerm]="true"
    [clearable]="false"
    bindLabel="text"
    bindValue="id"
    [formControlName]="dropDownControlName"
    (change)="dropDownControlFunction.emit($event)"
    placeholder="{{ dropDownControlPlaceholder | translate }}"
    class="form-control form-control-alternative"
></ng-select>
