<div class="input-group pf-search-input-group">
    <span class="input-group-text" id="search-input-group"><i class="ni ni-zoom-split-in text-secondary"></i></span>
    <input
        #searchInput
        type="text"
        class="form-control pf-search-input"
        aria-label="search input"
        aria-describedby="search-input-group"
        placeholder="{{ 'global.label.search' | translate }}"
    />
</div>
