import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidepanelComponent } from '@app/shared/sidepanel/sidepanel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalComponent } from './modal/modal.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DateObjectToFormatePipe } from './pipes/date-object-format.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { TimestampIsAfterPipe } from './pipes/timestamp-is-after.pipe';
import { TranslateI18nTitlecasePipe } from './pipes/translate-i18n-titlecase.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SearchParametersComponent } from './search-parameters/search-parameters.component';
import { SearchSelectParametersComponent } from './search-select-parameters/search-select-parameters.component';
import { ToastComponent } from './toast/toast.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { LanguageDropdownComponent } from '../components/language-dropdown/language-dropdown.component';
import { AbilityModule } from '@casl/angular';
import { CustomButtonGroupComponent } from './custom-button-group/custom-button-group.component';
import { AddIconComponent } from './add-icon/add-icon.component';
import { RemoveIconComponent } from './remove-icon/remove-icon.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UnixToDatePipe } from './pipes/unix-to-date.pipe';
import { UnixToTimePipe } from './pipes/unix-to-time.pipe';
import { UnixToDateTimePipe } from './pipes/unix-timestamp.pipe';

@NgModule({
	imports: [
		NgSelectModule,
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		LanguageDropdownComponent,
		NgxFileDropModule,
	],
	exports: [
		TranslateModule,
		LoaderComponent,
		ToastComponent,
		TooltipComponent,
		SidepanelComponent,
		ModalComponent,
		MomentPipe,
		SearchPipe,
		SafeUrlPipe,
		TruncatePipe,
		DateAgoPipe,
		TimestampIsAfterPipe,
		CountryDropdownComponent,
		SearchParametersComponent,
		MomentModule,
		TranslateI18nTitlecasePipe,
		SearchSelectParametersComponent,
		DateObjectToFormatePipe,
		AbilityModule,
		CustomButtonGroupComponent,
		AddIconComponent,
		RemoveIconComponent,
		FileUploadComponent,
		UnixToDatePipe,
		UnixToTimePipe,
		UnixToDateTimePipe,
	],
	declarations: [
		LoaderComponent,
		ToastComponent,
		TooltipComponent,
		ModalComponent,
		SidepanelComponent,
		MomentPipe,
		SearchPipe,
		SafeUrlPipe,
		TruncatePipe,
		DateAgoPipe,
		TimestampIsAfterPipe,
		CountryDropdownComponent,
		SearchParametersComponent,
		TranslateI18nTitlecasePipe,
		SearchSelectParametersComponent,
		DateObjectToFormatePipe,
		CustomButtonGroupComponent,
		AddIconComponent,
		RemoveIconComponent,
		FileUploadComponent,
		UnixToDatePipe,
		UnixToTimePipe,
		UnixToDateTimePipe,
	],
	providers: [TitleCasePipe]
})
export class SharedModule { }
