import { Component, OnInit, ViewChild } from '@angular/core';
import { IToast } from '@app/interfaces/IToast';
import { ToastService } from '@app/shared/toast/toast.services';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
    @ViewChild('toast')
    toast: any;
    allMessages: IToast[] = [];

    constructor(private toastService: ToastService) { }

    ngOnInit() {
        this.toastService.currentToast.subscribe(messages => {
            messages.map((message: IToast) => {
                if (message.hasOwnProperty('type')) {
                    this.allMessages.push(message);
                    setTimeout(() => {
                        if (this.allMessages.length === 1) {
                            this.toastService.setToast([{}]);
                            return;
                        }
                        this.allMessages.splice(0, 1);
                    }, message.timer);
                }
            });
        });
    }
}
